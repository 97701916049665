

import { defineComponent } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "details-section",
  components: {},
  props: {
    subject: String,
    module: String,
    title: String,
    createdAt: String,
    description: String,
    levels: Array,
    photos: Array,
  },
  setup() {
    const { t } = useI18n();
    return { moment, t };
  },
});
