import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card mb-8" }
const _hoisted_2 = { class: "card-body d-flex flex-center flex-column p-9" }
const _hoisted_3 = {
  key: 0,
  class: "symbol symbol-65px symbol-circle mb-5"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-white h-15px w-15px ms-n3 mt-n3"
}
const _hoisted_6 = {
  key: 1,
  class: "symbol symbol-65px symbol-circle mb-5"
}
const _hoisted_7 = {
  key: 0,
  class: "bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-white h-15px w-15px ms-n3 mt-n3"
}
const _hoisted_8 = {
  href: "#",
  class: "fs-4 text-gray-800 text-hover-primary fw-bolder mb-0"
}
const _hoisted_9 = { class: "fw-bold text-gray-400 mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.avatar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.avatar,
              alt: "image"
            }, null, 8, _hoisted_4),
            (_ctx.online)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("span", {
              class: _normalizeClass([`text-primary bg-light-primary`, "symbol-label fs-2x fw-bold"])
            }, _toDisplayString(_ctx.name[0]), 1),
            (_ctx.online)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7))
              : _createCommentVNode("", true)
          ])),
      _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.position), 1)
    ])
  ]))
}