
import { defineComponent, ref } from 'vue'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import ApiService from '@/core/services/ApiService'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { AxiosRequestConfig } from 'axios'
import { event } from 'vue-gtag'

interface Comment {
  _id: string
  createdAt: string
  updatedAt: string
  fullName: string
  photo: string
  text: string
}

export default defineComponent({
  name: 'comment-section',
  components: {},
  props: {
    type: String,
    id: String,
  },
  setup(props) {
    const { t } = useI18n()
    const store = useStore()
    const user = store.getters.currentUser
    const fullName = user.employee.firstName + ' ' + user.employee.lastName

    const comments = ref<Comment[]>([])

    ApiService.get(`/lms/comment/${props.type}/${props.id}`)
      .then(({ data }) => {
        data.forEach((comment: Comment) => {
          comment.photo = comment.photo
            ? store.getters.serverConfigUrl.base_url +
              '/' +
              comment.photo.replace(/\\/, '/')
            : 'media/avatars/blank.png'
          comments.value.push(comment)
        })
      })
      .catch((e) => {
        console.log(e)
      })

    const commentText = ref<string>('')

    const sendComment = (): void => {
      event('Send comment', {
        event_category: 'Research',
        event_label: 'Research section',
        value: 1,
      })

      const data = {
        teacher: user._id,
        text: commentText.value,
      } as AxiosRequestConfig
      data[props.type as string] = props.id

      ApiService.put('/lms/comment/', data)
        .then(({ data }) => {
          data.fullName = user.employee.firstName + ' ' + user.employee.lastName
          data.photo = user.employee.photo
            ? store.getters.serverConfigUrl.base_url +
              '/' +
              user.employee.photo.replace(/\\/, '/')
            : 'media/avatars/blank.png'
          comments.value.push(data)
          commentText.value = ''
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const deleteComment = (id: string): void => {
      Swal.fire({
        title: t('comment.confirmDelete'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: t('course.acceptDelete'),
      }).then((result) => {
        if (result.isConfirmed) {
          event('Delete comment', {
            event_category: 'Research',
            event_label: 'Research section',
            value: 1,
          })
          ApiService.delete(`/lms/comment/${id}`)
            .then(() => {
              comments.value = comments.value.filter(
                (comment) => comment._id != id
              )
              Swal.fire({
                text: t('comment.deleted'),
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: t('course.okay'),
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            })
            .catch((e) => {
              console.log(e)
              Swal.fire({
                text: t('course.errorText'),
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: t('course.tryAgain'),
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger',
                },
              })
            })
        }
      })
    }

    return {
      moment,
      t,
      comments,
      fullName,
      sendComment,
      deleteComment,
      commentText,
    }
  },
})
