
import { defineComponent, ref } from 'vue'
import { UploadFilled } from '@element-plus/icons-vue'
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import ApiService from '@/core/services/ApiService'
import { AxiosRequestConfig } from 'axios'
import { useRoute } from 'vue-router'
import { VueEditor } from 'vue3-editor'
import { event } from 'vue-gtag'

interface Submission {
  note?: number
  files?: string[]
  answer?: string
}

export default defineComponent({
  name: 'card-research-homework',
  components: { UploadFilled, VueEditor },
  props: {
    cardClasses: String,
    deadline: String,
    marked: Boolean,
    hid: String,
  },
  setup() {
    const { t } = useI18n()
    const route = useRoute()

    const answer = ref<string>('')
    const submitStat = ref<number>(0)
    const submitError = ref<boolean>(false)
    const submitMethod = ref<number>(0)

    const submission = ref<Submission>({})

    const submitButton = ref<HTMLButtonElement | null>(null)

    const uploadPercentage = ref(0)

    ApiService.get(`/teachers/research/submission/${route.params.id}`)
      .then(({ data }) => {
        if (data) submitStat.value = 2 // there is a submission
        else submitStat.value = 1 // there is no submission
        submission.value = data
      })
      .catch((e) => {
        console.log(e)
      })

    // eslint-disable-next-line
    const upload = ref<any>(null)

    // eslint-disable-next-line
    const uploadFiles = ref<any>([])

    const updateUploadFiles = (file, files) => {
      uploadFiles.value = files
    }

    const submit = () => {
      event('Submit research', {
        event_category: 'Research',
        event_label: 'Research section',
        value: 1,
      })

      // eslint-disable-next-line
      submitButton.value!.disabled = true
      submitButton.value?.setAttribute('data-kt-indicator', 'on')

      const data = new FormData()
      if (upload.value)
        uploadFiles.value.forEach((file) => {
          data.append('files', file.raw, file.raw.name)
        })

      if (answer.value) data.append('answer', answer.value)

      if (data.get('files') || data.get('answer'))
        ApiService.put(
          `/teachers/research/submit/${route.params.id}`,
          data as AxiosRequestConfig,
          {
            onUploadProgress: function (progressEvent) {
              uploadPercentage.value = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
            },
          } as any
        )
          .then(() => {
            submitStat.value = 2
            // eslint-disable-next-line
            submitButton.value!.disabled = false
            submitButton.value?.removeAttribute('data-kt-indicator')
          })

          .catch((e) => {
            console.log(e)
          })
      else {
        // eslint-disable-next-line
        submitButton.value!.disabled = false
        submitButton.value?.removeAttribute('data-kt-indicator')
        submitError.value = true
        setTimeout(() => {
          submitError.value = false
        }, 5000)
      }
    }

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return '#909399'
      }
      if (percentage < 70) {
        return '#e6a23c'
      }
      return '#67c23a'
    }

    return {
      submitMethod,
      submitButton,
      upload,
      submit,
      answer,
      moment,
      t,
      submitStat,
      submitError,
      submission,
      updateUploadFiles,
      uploadPercentage,
      customColorMethod,
    }
  },
})
