import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-8" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center text-align-center mx-auto w-100" }
const _hoisted_5 = { class: "card-body flex-column p-9" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["alt", "title", "src"]
const _hoisted_8 = {
  class: "fw-bold",
  style: {"display":"inline"}
}
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.t("resources")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "col d-flex flex-aligns-center pe-10 pe-lg-20 mb-3"
        }, [
          _createElementVNode("a", {
            target: "_blank",
            href: file.url
          }, [
            _createElementVNode("img", {
              alt: file.name,
              title: file.name,
              class: "w-30px h-30px me-2",
              src: `media/files/${file.type}.png`
            }, null, 8, _hoisted_7)
          ], 8, _hoisted_6),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("a", {
              target: "_blank",
              href: file.url,
              class: "fs-6 text-hover-primary fw-bolder"
            }, _toDisplayString(file.shortName), 9, _hoisted_9)
          ])
        ]))
      }), 128))
    ])
  ]))
}