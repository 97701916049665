

import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import AdvisorCard from "./components/AdvisorCard.vue";
import HomeworkCard from "./components/HomeworkCard.vue";
import RessourceCard from "./components/RessourceCard.vue";
import DetailsSection from "./components/DetailsSection.vue";
import CommentSection from "./components/CommentSection.vue";
import { useStore } from "vuex";

import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";

interface Research {
  _id: string;
  subject: string;
  title: string;
  module: string;
  description: string;
  createdAt: string;
  deadline: string;
  resources: string[];
  levels: string[];
  photos: string[];
}

interface Advisor {
  name: string;
  position: string;
  online: boolean;
  avatar?: string;
}

export default defineComponent({
  name: "research-details",
  components: {
    AdvisorCard,
    HomeworkCard,
    DetailsSection,
    CommentSection,
    RessourceCard,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const store = useStore();

    const researchID = route.params.id;

    const research = ref<Research>({
      _id: "",
      subject: "",
      title: "",
      module: "",
      description: "",
      createdAt: "",
      deadline: "",
      resources: [],
      photos: [],
      levels: [],
    });
    // eslint-disable-next-line
    const advisor = ref<Advisor>({
      name: "",
      position: "",
      online: true,
    });

    if (!researchID) router.push("/404");

    ApiService.get(`/teachers/research/${researchID}`)
      .then(({ data }) => {
        research.value = {
          subject: data.subject.name,
          _id: data._id,
          title: data.title,
          module: data.module,
          description: data.desc,
          createdAt: data.createdAt,
          deadline: data.deadline,
          resources: data.resources.filter((f) => !["png", "jpg"].includes(f.split('.')[1])),
          photos: data.resources.filter((f) => ["png", "jpg"].includes(f.split('.')[1])).map((p: string) => store.getters.serverConfigUrl.base_url +
            '/' + p),

          levels: data.levels,
        };
        advisor.value = {
          name: `${data.advisorName.firstName} ${data.advisorName.lastName}`,
          position: data.advisor.speciality,
          online: true,
          avatar: data.advisorName.photo
            ? store.getters.serverConfigUrl.base_url +
            "/" +
            data.advisorName.photo
            : "",
        };
        setCurrentPageBreadcrumbs(t("course.research"), [
          research.value.subject,
        ]);
      })

      .catch((e) => {
        console.log(e);
      });

    return { research, advisor, researchID, t };
  },
});
