
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "card-Advisor",
  components: {},
  props: {
    cardClasses: String,

    avatar: String,

    online: Boolean,

    name: String,

    position: String,
  },
  setup() {
    const { t } = useI18n();
    return { t };
  },
});
