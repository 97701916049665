

import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

interface File {
  name: string;
  type: string;
  url: string;
}

export default defineComponent({
  name: "card-Resources",
  components: {},
  props: {
    resources: Array,
  },
  setup(props) {
    const { t } = useI18n();
    const files = ref<File[]>();
    const store = useStore();
    const getFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      return fileName;
    };

    const getFileType = (file: string): string => {
      const type = file.split(".")[1];
      if (
        [
          "pdf",
          "png",
          "jpg",
          "ppt",
          "xls",
          "txt",
          "gif",
          "doc",
          "pptx",
          "xlsx",
          "docx",
          "zip",
          "mkv",
          "avi",
          "mp4",
          "mp3",
        ].includes(type)
      )
        return type;
      return "file";
    };

    const getFileURL = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    const getShortFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      if (fileName.length > 20)
        return (
          "..." + fileName.substring(fileName.length - 20, fileName.length)
        );
      return fileName;
    };

    files.value = props.resources?.map((file) => {
      const _file = file as string;
      return {
        name: getFileName(_file),
        shortName: getShortFileName(getFileName(_file)),
        type: getFileType(_file),
        url: getFileURL(_file),
      };
    });

    return { t, files };
  },
});
